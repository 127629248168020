function initSearch() {
    const searchResults = document.getElementById('search-results');
    const searchBox = document.getElementById('search');
    const navSearch = document.querySelector('.nav-search ');
    const navParent = document.querySelector('.nav-searchbar-wrap');

    if (!navSearch || !searchBox) {
        // Landing pages don't have search
        return;
    }

    function UpdateActiveClasses(e) {
        const isTargetInSearchContainer = navParent.contains(e.target);
        const isTargetSVGContainer = e.target.classList.contains('search-icon');
        const isTargetTheSearchButton = e.target === searchBox;
        const isSearchOpen = navParent.classList.contains('searchbar--active');

        if (!isTargetInSearchContainer && !isTargetSVGContainer && !isTargetTheSearchButton && isSearchOpen) {
            navParent.classList.remove('searchbar--active');
        } else if (isTargetTheSearchButton) {
            searchBox.classList.add('input--active');
        }
    }

    navSearch.addEventListener('click', () => {
        if (window.innerWidth > 991) {
            navParent.classList.toggle('searchbar--active');
        }
    });

    window.addEventListener('click', (e) => {
        UpdateActiveClasses(e);
    });

    function debounce(func, timeout = 300) {
        let timer;
        return (...args) => {
            clearTimeout(timer);
            timer = setTimeout(() => {
                func.apply(this, args);
            }, timeout);
        };
    }

    searchBox.addEventListener('input', debounce((e) => {
        const search_query = e.target.value;
        const search_regex = RegExp("[0-9A-Za-z-+$™®©% '()]{3,256}");
        const regexed_query = search_regex.exec(search_query); // Only allow the above characters to even go to search
        const filtered_query = regexed_query ? regexed_query[0] : ''; // if we don't find anything from the regex we just pass nothing.

        if (filtered_query.length > 2) {
            const encoded_query = encodeURIComponent(filtered_query); // Only URI encode after we know this is a 'good' value.
            fetch(`/money/search/partial/?q=${encoded_query}`, { headers: { 'X-Requested-With': 'XMLHttpRequest' } })
                .then((response) => {
                    response.text().then((text) => {
                        if (text.trim() === '') {
                            searchResults.style.display = 'none';
                        } else {
                            searchResults.innerHTML = text;
                            searchResults.style.display = 'block';
                        }
                    });
                });
        } else {
            searchResults.style.display = 'none';
        }
    }));

    navParent.addEventListener('keydown', (e) => {
        if (e.key === 'Escape' || e.key === 'Esc') {
            searchResults.style.display = 'none';
            navParent.classList.remove('searchbar--active');
            document.activeElement.blur();
        }
    });

    searchBox.addEventListener('keydown', (e) => {
        if (e.key === 'Enter') {
            const term = searchBox.value;
            const showMoreResultsLink = document.getElementById('show-more-link');
            if (showMoreResultsLink) {
                window.location = showMoreResultsLink.href;
            } else {
                window.location = `/money/search/?q=${encodeURIComponent(term)}`;
            }
        }
    });

    function checkMouseClick(event, flyoutElement) {
        let targetElement = event.target;
        do {
            if (targetElement === flyoutElement) {
                return false;
            }
            targetElement = targetElement.parentNode;
        } while (targetElement);

        return true;
    }

    document.body.addEventListener('click', (event) => {
        if (checkMouseClick(event, document.getElementsByClassName('search-bar-main')[0])) {
            searchResults.style.display = 'none';
        }
    });
}

document.addEventListener('readystatechange', (event) => {
    if (event.target.readyState === 'complete') {
        initSearch();
    }
});
